import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import {
  getDefaultProductCode,
  getFormattedTradingSymbolObject,
  getPlaceOrderTradingSymbol,
} from '../tradingSymbolParser';
import { parseOrderDetailToGetTradingSymbolObj } from './workmate';
import symbolID from './security_id.json';
import { isCurrentTimeWithinTradingHours } from 'habitual-analytics/utils/datetime';

const parseOrderType = (orderType) => {
  let type;
  switch (_.toLower(orderType)) {
    case 'limit':
    case 'l':
      type = 'LIMIT';
      break;
    case 'mkt':
      type = 'MARKET';
      break;
    case 'sl':
      type = 'SL';
      break;
    case 'sl-m':
      type = 'SL-M';
      break;
    default:
      type = '';
  }
  return type;
};

const defaultParseOrderType = (orderType) => {
  let type;
  switch (_.toLower(orderType)) {
    case 'limit':
    case 'l':
      type = 'L';
      break;
    case 'mkt':
      type = 'MARKET';
      break;
    case 'sl':
      type = 'SL';
      break;
    case 'sl-m':
      type = 'SL-M';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pcode, tradingSymbolObj) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
      productCode = tradingSymbolObj?.isEquity() ? 'DELIVERY' : 'OVERNIGHT';
      break;
    case 'mis':
      productCode = 'INTRADAY';
      break;
    case 'nrml':
      productCode = 'OVERNIGHT';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail, quantity) => {
  let status = statusConfigs.placed.value;
  const isOrderFailed = _.includes(orderDetail.status, 'Rejected');
  if (isOrderFailed) {
    return statusConfigs.failed.value;
  };

  switch (_.lowerCase(orderDetail.status)) {
    case 'traded':
      status = statusConfigs.executed.value;
      break;
    case 'cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      quantity,
      orderDetail.filled_quantity
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseSubOrderBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trigger_price', 0));
  const AcutalPrice = convertToNumber(_.get(orderDetail, 'price', 0));
  const price = !AcutalPrice
    ? convertToNumber(
      _.get(orderDetail, 'total_traded_value', 0) /
      _.get(orderDetail, 'quantity', 0)
    )
    : AcutalPrice;
  const tradedQty = _.get(orderDetail, 'filled_quantity', 0);
  const quantity =
    Number(_.get(orderDetail, 'filled_quantity', 0)) +
    Number(_.get(orderDetail, 'pending_quantity', 0));
  const avgPrice = convertToNumber(_.get(orderDetail, 'average_price', 0));
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice.toFixed(2)
        : price.toFixed(2);
  const tradedTime = _.get(orderDetail, 'order_timestamp', '') || '';
  const status = sanitizeAndParseOrderStatus(orderDetail, quantity);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'status', '');

  return {
    tradingSymbolObj,
    time: moment(tradedTime, 'DD/MM/YYYY HH:mm:ss')?.format(
      PRETTIER_FORMAT_WITH_SECONDS
    ),
    type:
      _.get(orderDetail, 'transaction_type', '') === 'Sell'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${Math.abs(tradedQty)} / ${Math.abs(quantity)}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'order_id', ''),
      defaultProductType: defaultParseOrderType(productType),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
    },
  };
};

const parseOrderBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);

  if (isArrayDetails) {
    return _.map(orderDetail, parseSubOrderBook);
  }
  return parseSubOrderBook(orderDetail);
};

const parseSubTradeBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }
  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product', '');
  const tradedQuantity = Number(_.get(orderDetail, 'filled_quantity', 0));
  const quantity =
    Number(_.get(orderDetail, 'filled_quantity', 0)) +
    Number(_.get(orderDetail, 'pending_quantity', 0));
  const status = statusConfigs.executed.label;
  const tradedTime =
    _.split(_.get(orderDetail, 'fill_timestamp', ''), ' ')[1] || '';

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'transaction_type', '') === 'SELL'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'average_price', 0)),
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);

  if (isArrayDetails) {
    return _.map(orderDetail, parseSubTradeBook);
  }
  return parseSubTradeBook(orderDetail);
};

const parseSubPositionBook = (orderDetail) => {
  const tradingSymbolObj = parseOrderDetailToGetTradingSymbolObj(orderDetail);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  const ltp = convertToNumber(_.get(orderDetail, 'ltp'));
  const qty = Number(_.get(orderDetail, 'net_qty'), '0');
  const buyAvgPrice = _.get(orderDetail, 'average_buy_price', 0);
  const sellAvgPrice = _.get(orderDetail, 'average_sell_price', 0);

  const realisedProfitLoss = _.get(
    orderDetail,
    'realised_pl_overall_position',
    0
  );
  const unRealisedProfitLoss =
    qty === 0
      ? 0
      : qty > 0
        ? (ltp - buyAvgPrice) * qty
        : (ltp - sellAvgPrice) * qty;

  const buyAvg = _.round(convertToNumber(buyAvgPrice), 2);
  const sellAvg = _.round(convertToNumber(sellAvgPrice), 2);

  const transactionTypes = { buy: { value: 'buy' }, sell: { value: 'sell' } };
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const profitLoss = realisedProfitLoss + unRealisedProfitLoss;

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    symbol: tradingSymbolObj.toString(),
    extraDetails: {
      product: _.get(orderDetail, 'product', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }
  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const isin = _.get(orderDetail, 'isin', '');
  const isinCodes = MarketUtility.getAllIsinCodes;

  if (!_.includes(isinCodes, isin)) {
    return null;
  }

  const tradingSymbol = MarketUtility.getSymbolByIsinCode(isin);
  const quantity = Number(_.get(orderDetail, 'quantity', 0));
  const orderValue = convertToNumber(_.get(orderDetail, 'average_price', 0));
  const buyValue = quantity * orderValue;
  const ltp = convertToNumber(_.get(orderDetail, 'close_price', 0));
  const profitLoss = getRoundedData((ltp - orderValue) * quantity);
  const netChg = getRoundedData((profitLoss / buyValue) * 100);

  const tradingSymbolObj = getFormattedTradingSymbolObject(
    `${tradingSymbol}-EQ`
  );

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${_.get(orderDetail, 't1_quantity', 0)})`,
      buyAverage: orderValue,
      buyValue: buyValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'close_price',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const getInstumentSegment = (tradingSymbol) => {
  let instrumentSegment;

  switch (true) {
    case tradingSymbol.isFuture():
      instrumentSegment = tradingSymbol.isIndex() ? 'FUTIDX' : 'FUTSTK';
      break;
    case tradingSymbol.isOption():
      instrumentSegment = tradingSymbol.isIndex() ? 'OPTIDX' : 'OPTSTK';
      break;
    case tradingSymbol.isEquity():
      instrumentSegment = 'EQUITY';
      break;
    default:
      instrumentSegment = '';
  }
  return instrumentSegment;
};

const parsePlaceOrder = async (orderConfig) => {
  const { tradingSymbolObj } = orderConfig;

  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);

  const symbolId = tradingSymbolObj?.isEquity()
    ? symbolID[tradingSymbolObj?.toString()]
    : await getExchangeToken(formattedExchangeTokenTradingSymbol);

  return {
    exchange: 'NSE',
    security_id: symbolId,
    expiry_date: _.join(_.split(tradingSymbolObj?.expiryDate, '-'), ''),
    strike_price: tradingSymbolObj?.strikePrice,
    option_type: tradingSymbolObj?.optionType,
    transaction_type: _.capitalize(_.get(orderConfig, 'transactionType', '')),
    product: parseProductCode(
      _.get(orderConfig, 'pCode', ''),
      tradingSymbolObj
    ),
    quantity: _.get(orderConfig, 'qty', 0),
    order_type: parseOrderType(_.get(orderConfig, 'prctyp', '')),
    price: _.get(orderConfig, 'price', 0),
    trigger_price: _.get(orderConfig, 'trigPrice', 0),
    validity: _.get(orderConfig, 'ret', ''),
    instrument_segment: getInstumentSegment(tradingSymbolObj),
    amo: !isCurrentTimeWithinTradingHours(),
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

const parseModifyOrder = (orderConfig) => {
  return {
    quantity: _.get(orderConfig, 'qty', ''),
    product: parseProductCode(_.get(orderConfig, 'pCode', '')),
    order_type: parseOrderType(_.get(orderConfig, 'prctyp', '')),
    validity: _.get(orderConfig, 'ret', ''),
    price: _.get(orderConfig, 'price', ''),
    trigger_price: _.get(orderConfig, 'trigPrice', ''),
    disclosed_quantity: _.get(orderConfig, 'disclosedQty', ''),
    amo: !isCurrentTimeWithinTradingHours(),
  };
};
const parseBrokerageCharges = (orderDetail, brokerClientId, INVESTRIGHT_APP_ID) => {
  const { tradingSymbolObj } = orderDetail;
  const exchangeSegement = tradingSymbolObj.getExchangeSegment();
  return {
    'api_key': INVESTRIGHT_APP_ID,
    'buy-sell': _.get(orderDetail, 'transactionType', '') === 'buy' ? 'B' : 'S',
    'client-id': brokerClientId,
    'exchange': exchangeSegement,
    'price': 0.05,
    'product': parseProductCode(_.get(orderDetail, 'pCode', '')),
    'quantity': _.get(orderDetail, 'qty', 0),
    'trading-symbol': getPlaceOrderTradingSymbol(tradingSymbolObj),
  };
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
  parseModifyOrder,
  parseBrokerageCharges,
};
